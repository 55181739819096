<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="data.data"
      :sort-mapping="sortMapping"
      :title="'applicants'"
      :total="applicantsTotal"
      :fetch-path="'convo/fetchApplicants'"
      :export-path="'convo/exportApplicants'"
      :filter-path="'convo/fetchApplicants'"
      :evaluators-path="'convo/assignEvaluators'"
      :actions="true"
      :applicants="true"
      @selectedItems="saveSelectedItems"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <strong><span class="ps-2">Evaluators</span></strong>
              <b-dropdown-item
                v-for="evaluator, index in field.evaluators"
                :key="evaluator.id"
                @click="removeEvaluatorTable(field, index)"
              >
                <i
                  data-feather="check"
                  :class="{'d-none': evaluator.pivot && (evaluator.pivot.competent === false || evaluator.pivot.competent === null)}"
                  class="text-success me-1"
                />
                <i
                  :class="{'d-none': evaluator.pivot && (evaluator.pivot.competent || evaluator.pivot.competent === null)}"
                  data-feather="x"
                  class="text-danger me-1"
                />
                <i
                  :class="{'d-none': evaluator.pivot && evaluator.pivot.competent !== null}"
                  data-feather="circle"
                  class="text-secondary me-1"
                />
                {{ evaluator.name }}
              </b-dropdown-item>
              <div class="dropdown-divider" />
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormData', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form data
              </b-dropdown-item>
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormEvaluation', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form evaluation
              </b-dropdown-item>
              <b-dropdown-item
                :href="$router.resolve({ name: 'admin.users.edit', params: { id: field.user_id }, query: { actAs: $route.query.actAs } }).href"
                target="_blank"
              >
                <i
                  class="me-50"
                  data-feather="edit"
                />
                Edit
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div
          class="mb-1"
        >
          <label
            for="nameseniorcall"
            class="form-label"
          >Candidate</label>
          <v-select
            v-model="filters.candidate"
            :filter="customFilter"
            label="full_name"
            :options="applicantData"
            :get-option-key="option => option.id"
            :placeholder="'Type 3 or more characters to search...'"
            @search="onSearch({ candidate: $event }, 'convo/fetchApplicants')"
            @input="saveFilter({ customLabel: true, name: `Candidate: ${$event ? $event.full_name : ''}`, value: $event }, 'candidate')"
          />
          <hr>
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Status</label>
          <v-select
            v-model="filters.status"
            label="name"
            :options="[
              {
                name: 'Elegibles',
                value: '996'
              },
              {
                name: 'Not eligibles',
                value: '3'
              },
            ]"
            :get-option-key="option => option.value"
            @input="saveFilter({ customLabel: true, name: `Status: ${getStatusName($event.value)}`, value: $event }, 'status')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Group</label>
          <v-select
            v-model="filters.area"
            label="areaName"
            :options="convo.areas"
            :get-option-key="option => option.areaId"
            @input="saveFilter({ customLabel: true, name: `Group: ${$event ? $event.name : ''}`, value: $event }, 'area')"
          />
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >Area</label>
          <v-select
            v-model="filters.area_user"
            label="areaName"
            :options="convo.areas.filter(e => {
              if (e.parent_id) {
                return false
              }

              return true
            })"
            :get-option-key="option => option.areaId"
            @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area_user')"
          />
        </div>
      </template>
    </CompleteTable>
    <ShowFormData :selected="selectedItem" />
    <ShowFormEvaluation :selected="selectedItem" />
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import ShowFormData from './ShowFormData.vue'
import ShowFormEvaluation from './ShowFormEvaluation.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    ShowFormData,
    ShowFormEvaluation,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      selectedItem: {},
      area: null,
      selectedEvaluators: [],
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Group',
          checked: true,
          order: 3,
        },
        {
          name: 'Status',
          checked: true,
          order: 4,
        },
        {
          name: 'Feedback Editor',
          checked: true,
          order: 5,
        },
        {
          name: 'ERC Codes',
          checked: true,
          order: 6,
        },
        {
          name: 'AVG Mark',
          checked: true,
          order: 7,
        },
        {
          name: 'Num Academia calls',
          checked: true,
          order: 8,
        },
        {
          name: 'Num Senior calls',
          checked: true,
          order: 9,
        },
        {
          name: 'Moderator',
          checked: true,
          order: 10,
        },
        {
          name: 'Evaluators',
          checked: true,
          order: 11,
        },
      ],
      sortMapping: {
        Name: 'full_name',
        Year: 'year',
        Group: 'assigned_group',
        Status: 'status.status_table',
        'Feedback Editor': 'feedback_editor.name',
        'ERC Codes': 'erc',
        'AVG Mark': 'avg_grade',
        'Num Academia calls': 'num_calls',
        'Num Senior calls': 'num_calls_senior',
        Moderator: 'moderator',
        Area: 'area_code',
        Institution: 'institution_code',
        Evaluators: 'evaluated',
      },
      selectedItems: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      filters: 'filters/filters',
      applicantsTotal: 'convo/applicantsTotal',
      applicantData: 'convo/applicants',
      fields: 'modals/tableSettingsFields',
      ercs: 'erc/items',
    }),
    data() {
      return {
        data: this.applicantData,
        total: this.applicantData.length,
      }
    },
    areasSelect() {
      let areas = []

      if (this.selectedEvaluators.length > 1) {
        areas = this.convo.areas.filter(e => {
          if (!e.parent_id && e.hasChildrens) {
            return false
          }

          return true
        })
      } else if (this.selectedEvaluators.length === 1) {
        this.selectedEvaluators[0].areas.forEach(e => {
          if (e.childrens.length > 0) {
            areas = areas.concat(e.childrens)
          } else {
            areas.push(e)
          }
        })
      }

      return areas
    },
  },
  async mounted() {
    this.$store.dispatch('pagination/clearPage')
    this.$store.dispatch('pagination/clearPerPage')
    await this.$store.dispatch('convo/fetchApplicants', { id: this.convo.id, status: 993, ...this.filters })
    this.$store.dispatch('modals/fetchUserFields', 'applicants_table')
    this.$store.dispatch('erc/fetch')
    this.loading = false

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'applicants_table',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    customFilter(option, query) {
      // Perform case-insensitive and accent-insensitive matching
      return this.applicantData.filter(e => e.full_name && e.full_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query))
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    assignToGroup(area, field) {
      this.area = area
      this.selectedEvaluators = []
      this.selectedEvaluators.push(field)
      this.applyActions()
    },
    removeEvaluator(area, field) {
      Vue.swal({
        title: 'Do you want to remove this evaluator?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('convo/assignEvaluators', {
            convoId: this.convo.id,
            area,
            evaluators: [field],
            remove: true,
          })
        }
      })
    },
    applyActions() {
      if (this.area && this.selectedEvaluators.length > 0) {
        Vue.swal({
          title: 'Do you want to add this evaluators?',
          html: `Assign evaluators to the group ${this.area.name}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes, apply!',
        }).then(result => {
          if (result.isConfirmed) {
            this.$store.dispatch('convo/assignEvaluators', {
              convoId: this.convo.id,
              area: this.area,
              evaluators: this.selectedEvaluators,
            })
            this.selectedEvaluators = []
            this.area = null
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
          }
        })
      } else {
        this.$toastr.error('', 'Please, select evaluator and group before applying')
      }
    },
    async onSearch(search, store) {
      const s = search
      if (s.name !== '') {
        s.candidate = s.candidate.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        await this.$store.dispatch(store, s)
      }
    },
    getStatusName(value) {
      let str = ''
      switch (value) {
        case '0':
          str = 'All applicants'
          break
        case '3':
          str = 'Not elegible'
          break
        case '4':
          str = '1st round'
          break
        case '5':
          str = '2nd round'
          break
        case '6':
          str = 'Ranked'
          break
        case '7':
          str = 'Offered'
          break
        case '8':
          str = 'Position accepted'
          break
        case '996':
          str = 'Elegible'
          break
        default:
          break
      }
      return str
    },
    async removeEvaluatorTable(field, index) {
      Vue.swal({
        title: 'Do you want to remove this evaluator?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, remove!',
      }).then(result => {
        if (result.isConfirmed) {
          const { evaluators } = field
          evaluators.splice(index, 1)

          this.$store.dispatch('convo/assignApplicants', {
            convoId: this.convo.id,
            applicants: [field],
            evaluators: evaluators.map(e => e.id),
            sync: true,
          }).then(() => {
            Vue.swal({
              title: 'Evaluator removed successfully',
              html: '',
              icon: 'success',
            })
          })
        }
      })
    },
  },
}
</script>

<template>
  <div
    class="card card--table"
  >
    <div
      v-b-toggle="`collapseApplicant-${applicant.user_id}`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">{{ applicant.full_name }} </a>
      </h3>
      <div class="heading-elements d-flex">
        <span
          v-if="applicant.evaluators.some(e => e.pivot.feedback_editor)"
          class="badge bg-light-success me-4"
        >Assigned</span>
        <span
          v-else
          class="badge bg-light-secondary me-4"
        >Pending</span>
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseApplicant-${applicant.user_id}`"
      :visible="visibleAll"
    >
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <table class="table mb-2">
                <thead>
                  <tr>
                    <th>Area</th>
                    <th>Host institution</th>
                    <th>ERC CODE</th>
                    <th>AVG Grade</th>
                    <th>AVG Normalize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ applicant.area }}</td>
                    <td>{{ applicant.institution }}</td>
                    <td>{{ applicant.erc }}</td>
                    <td>{{ applicant.avg_grade }}</td>
                    <td>{{ applicant.avg_normalized }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="col-sm-6">
                  <p><strong>Research interest</strong></p>
                  <p><small v-html="applicant.research_interests ? applicant.research_interests.replace(/<[^>]*>/g, '') : ''" /></p>
                </div>
                <div class="col-sm-6 container__keywords">
                  <p><strong>Keywords</strong></p>
                  <template v-if="applicant.keywords">
                    <span
                      v-for="keyword in applicant.keywords.replace(/<[^>]*>/g, '').split(/[\/,\;\.]/)"
                      :key="keyword"
                      class="badge bg-dark me-1"
                      style="margin-bottom: 5px;"
                      v-html="keyword"
                    />
                  </template>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <ul class="list-group">
                <li
                  v-for="evaluator, index in applicant.evaluators"
                  :key="evaluator.id"
                  class="list-group-item d-flex align-items-center"
                  :class="{'bg-light-success': evaluator.pivot.feedback_editor && !evaluator.pivot.interest_conflict, 'bg-light-secondary': evaluator.pivot.interest_conflict}"
                >
                  <div class="form-check form-check-success">
                    <input
                      v-if="!evaluator.pivot.interest_conflict"
                      class="form-check-input"
                      type="radio"
                      :value="evaluator.id"
                      :name="`flexRadio-${applicant.user_id}`"
                      :checked="evaluator.pivot.feedback_editor"
                      @click="selectFeedback(evaluator, index)"
                    >
                    <label
                      class="form-check-label text-dark"
                    >
                      <strong>{{ evaluator.last_name }}, {{ evaluator.first_name }} ({{ evaluator.institution_name }}{{ evaluator.department_name ? `, ${evaluator.department_name}` : '' }})</strong> <span class="badge bg-info ms-2">Mark: {{ evaluator.pivot.avg_grade }}</span>
                      <span
                        v-if="evaluator.pivot.interest_conflict"
                        class="badge bg-secondary ms-2"
                      >Marked as interest conflict</span>
                      <div class="mt-1 container__keywords">
                        <span
                          class="badge bg-secondary me-1"
                        >{{ evaluator.erc ? evaluator.erc.code : '-' }}</span>
                        <span
                          v-for="keyword in evaluator.keywords"
                          :key="keyword.id"
                          class="badge bg-dark me-1"
                          style="margin-bottom: 5px;"
                        >{{ keyword.word }}</span>
                      </div>
                    </label>
                  </div>
                  <span
                    class="badge bg-light-success rounded-pill ms-auto"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Number of applicants assigned"
                  >{{ evaluator.hasApplicants }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BCollapse } from 'bootstrap-vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    applicant: { type: Object, required: true },
    visibleAll: { type: Boolean, required: true },
    area: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      applicants: 'convo/applicants',
      fields: 'modals/tableSettingsFields',
    }),
  },
  methods: {
    async selectFeedback(evaluator, index) {
      const evaluatorLast = this.applicant.evaluators.findIndex(e => e.pivot.feedback_editor === true)

      if (this.applicant.evaluators[evaluatorLast]) {
        this.applicant.evaluators[evaluatorLast].hasApplicants -= 1
        this.applicant.evaluators[evaluatorLast].pivot.feedback_editor = false
      }

      this.applicant.evaluators[index].hasApplicants += 1
      this.applicant.evaluators[index].pivot.feedback_editor = true
      await this.$store.dispatch('convo/assignFeedback', {
        user_id: this.applicant.user_id,
        evaluator_id: evaluator.id,
        convoId: this.convo.id,
      })
      this.$store.dispatch('convo/filterEvaluators', { area: this.area })
      this.$forceUpdate()
    },
  },
}
</script>

<template>
  <div>
    <div class="card card--table">
      <div
        class="card-body"
        :class="{'d-none': loading}"
      >
        <div
          class="row"
        >
          <div class="col-sm-4">
            <h4><strong>{{ pendingEvaluators }} / {{ evaluatorsTotal }}</strong> feedback editors</h4>
            <div class="form-check form-check-success form-switch d-flex align-items-center">
              <input
                id="publishContent"
                type="checkbox"
                class="form-check-input"
                @click="hideAssigneds"
              >
              <label
                class="form-check-label"
                for="publishContent"
              ><small class="ms-1">Hide assigneds </small></label>
              <template v-if="area && area.length > 0">
                <input
                  id="publishContent1"
                  v-model="showEvaluators"
                  type="checkbox"
                  class="form-check-input ms-2"
                  value="1"
                >
                <label
                  class="form-check-label"
                  for="publishContent1"
                ><small class="ms-1">Show evaluators </small></label>
              </template>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col-sm-4">
                <div class="">
                  <label
                    for=""
                    class="form-label"
                  >Filter by area</label>
                  <v-select
                    v-model="area"
                    multiple
                    label="areaName"
                    :options="convo.areas"
                    :get-option-key="option => option.areaId"
                    @input="filter"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label
                    for=""
                    class="form-label"
                  >Filter by evaluator</label>
                  <v-select
                    label="name"
                    :options="evaluators"
                    :get-option-key="option => option.id"
                    @input="filterEvaluator"
                    @search="onSearch({ name: $event }, 'convo/filterEvaluators')"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Candidate</label>
                  <v-select
                    :filter="customFilter"
                    label="full_name"
                    :options="applicants"
                    :get-option-key="option => option.id"
                    :reduce="e => e.user_id"
                    @input="filterCandidate"
                    @search="onSearch({ candidate: $event }, 'convo/fetchApplicants')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div
          v-if="showEvaluators && area && area.length > 0"
          class="stats__evaluators"
        >
          <ul class="list-group list-group-horizontal-sm">
            <li
              v-for="evaluator in evaluators"
              :key="evaluator.id"
              class="list-group-item"
            >
              {{ evaluator.name }}
              <span class="badge badge-light-success ms-1">
                {{ evaluator.hasApplicants }}
              </span>
              <br>
              <span
                v-if="evaluator.area"
                class="badge bg-light-secondary"
              >
                {{ evaluator.area ? evaluator.area.code : '-' }}
              </span>
              <span class="badge bg-light-secondary ms-1">
                {{ evaluator.erc ? evaluator.erc.code : '-' }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <template v-if="!loading">
      <FeedbackEditorApplicant
        v-for="applicant in applicants"
        :key="applicant.user_id"
        :applicant="applicant"
        :visible-all="visibleAll"
        :area="area"
      />
    </template>

    <div class="card-body">
      <div class="d-flex align-items-center justify-content-between">
        <Paginator
          :total="applicantsTotal"
          :store="'convo/fetchApplicants'"
          @loading="setLoading"
        />
        <div>
          <select
            v-model="perPage"
            class="form-select"
            @change="setPerPage"
          >
            <option value="15">
              15 items per page
            </option>
            <option value="50">
              50 items per page
            </option>
            <option value="75">
              75 items per page
            </option>
            <option value="10000">
              All items per page
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Paginator from '@/views/back/partials/Paginator.vue'
import FeedbackEditorApplicant from './FeedbackEditorApplicant.vue'

export default {
  components: {
    vSelect,
    FeedbackEditorApplicant,
    Paginator,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
  },
  data() {
    return {
      loading: true,
      showEvaluators: false,
      area: null,
      visibleAll: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
      evaluatorsTotal: 'convo/evaluatorsTotal',
      applicantsTotal: 'convo/applicantsTotal',
      applicants: 'convo/applicants',
      perPage: 'pagination/perPage',
    }),
    pendingEvaluators() {
      return this.evaluators.filter(e => e.hasApplicants !== 0).length
    },
  },
  async mounted() {
    this.resetCard()
  },
  methods: {
    customFilter(option, query) {
      // Perform case-insensitive and accent-insensitive matching
      return this.applicants.filter(e => e.full_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query))
    },
    filterCandidate(event) {
      this.loading = true
      this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 }, candidate: event }).then(() => { this.loading = false })
    },
    async resetCard() {
      await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      this.loading = false
    },
    async filter() {
      this.loading = true
      this.saveFilter(this.area, 'area')
      this.saveFilter({ value: 996 }, 'status')
      this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 } })
      await this.$store.dispatch('convo/filterEvaluators', { area: this.area })
      this.showEvaluators = true
      this.loading = false
    },
    filterEvaluator(event) {
      this.loading = true
      if (event) {
        this.$store.dispatch('convo/fecthApplicantsByEval', { id: this.$route.params.id, user_id: event.id }).then(() => { this.loading = false })
      } else {
        this.resetCard()
      }
    },
    hideAssigneds(event) {
      if (event.target.checked) {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 998 } })
      } else {
        this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: 996 } })
      }
    },
    async setPerPage(perPage) {
      this.selectedItems = []
      this.setLoading(true)
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      await this.$store.dispatch('convo/fetchApplicants', { area: this.area, status: { value: 996 } })
      this.setLoading(false)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    setLoading(loading) {
      this.loading = loading
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if ((search.name && search.name.length > 2) || (search.candidate && search.candidate.length > 2)) {
        if (search.candidate) {
          search.candidate = search.candidate.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
